<template>
  <div>
    <b-button
      @click="$router.go(-1)"
      variant="outline-warning"
      class="btn-icon my-1"
      size="sm"
    >
      <span class="">RETOUR</span>
    </b-button>
    <b-card class="" title="INFORMATIONS DU COMPTE">
      <div class="row px-2 border-top">
        <b-card
          class="mb-0 block-text block-text-media"
          style="margin-right: 14%"
        >
          <div class="row mb-2">
            <b-icon-building
              class="h3 text-primary"
              style="margin-top: 5%; margin-right: 10px"
            ></b-icon-building>
            <b-card-text>
              <span style="color: #b4b7bd">COMPTE</span> <br />
              {{ compte.libelle }}
            </b-card-text>
          </div>
        </b-card>
      </div>
    </b-card>

    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div :hidden="!hiddenLoader">
      <b-card class="mt-1 block-text" title="">
        <div class="border-bottom pb-2">
          <span class="h4" style="margin-top: 5px">LISTE DES OPERATIONS</span>
          <div class="float-right">
            <b-button
              v-if="$hasFonction(user, 'Voir compte')"
              size="sm"
              class="ml-1"
              @click="showReport('print')"
              variant="outline-primary"
            >
              <b-icon-printer-fill></b-icon-printer-fill>
              <span style="margin: 0px 5px">IMPRIMER</span>
            </b-button>
            <b-button
              v-if="$hasFonction(user, 'Voir compte')"
              size="sm"
              class="ml-1"
              @click="ExportFomatExcel"
              variant="outline-primary"
            >
              <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
              <span style="margin: 0px 5px">EXPORTER</span>
            </b-button>

            <b-button
              v-if="$hasFonction(user, 'Voir compte')"
              size="sm"
              class="ml-1 mt-sm-1"
              @click="ExportAllToFomatExcel"
              variant="outline-primary"
            >
              <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
              <span style="margin: 0px 5px">TOUT EXPORTER</span>
            </b-button>


          </div>
        </div>

        <b-card no-body title="" class="mb-0">
          <div class="mt-2 row">
            <!-- champ recherche start -->
            <b-form-group class="col">
              <b-form-input
                placeholder="Rechercher"
                type="text"
                class="d-inline-block"
                @input="filterRecherche"
              />
            </b-form-group>
            <!-- champ recherche end -->

            <!-- date start -->
            <div class="col">
              <b-form-datepicker
                v-model="startDateFilterValue"
                placeholder="Date de debut"
                labelHelp="Utilisez les touches du curseur pour naviguer dans les dates du calendrier."
                labelNoDateSelected="Aucune date sélectionnée"
                labelResetButton="Annuler"
                reset-button
                :date-format-options="dateFormatOptions"
                locale="fr"
                @input="filterValue()"
              ></b-form-datepicker>
            </div>

            <div class="col">
              <b-form-datepicker
                v-model="endDateFilterValue"
                placeholder="Date de fin"
                labelHelp="Utilisez les touches du curseur pour naviguer dans les dates du calendrier."
                labelNoDateSelected="Aucune date sélectionnée"
                labelResetButton="Annuler"
                reset-button
                :date-format-options="dateFormatOptions"
                locale="fr"
                @input="filterValue()"
              ></b-form-datepicker>
            </div>
            <!-- date end -->

            <!-- select importateur start -->
            <div class="col" v-if="showCaisseFilter">
              <b-form-group>
                <b-form-select
                  :options="caisse_concernee"
                  id="caisse"
                  v-model="CaisseCoFilterValue"
                  value-field="name"
                  text-field="name"
                  :placeholder="
                    compte.libelle == 'Carte Importateur' ||
                    compte.libelle == 'Generation Facture'
                      ? 'Association / Importateur'
                      : compte.libelle == 'Retrait Associés'
                      ? 'Associés'
                      : 'Association'
                  "
                  @input="filterValue()"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      style="color: #b4b7bd; text-align: center"
                    >
                      {{
                        compte.libelle == "Carte Importateur" ||
                        compte.libelle == "Generation Facture"
                          ? "Association / Importateur"
                          : compte.libelle == "Retrait Associés"
                          ? "Associés"
                          : "Association"
                      }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <!-- select importateur end -->

            <!-- select Personnel Concerné start -->
            <div class="col" v-if="showMembresFilter">
              <b-form-group>
                <b-form-select
                  :options="membres"
                  id="caisse"
                  v-model="MembresFilterValue"
                  value-field="name"
                  text-field="name"
                  placeholder="Personnel Concerné"
                  @input="filterValue()"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      style="color: #b4b7bd; text-align: center"
                    >
                      -- Personnel Concerné --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <!-- select Personnel Concerné end -->

            <!-- select Personnel Concerné start -->
            <div class="col" v-if="showMembresFilter">
              <b-form-group>
                <b-form-select
                  :options="creanciers"
                  id="caisse"
                  v-model="CreancierilterValue"
                  value-field="name"
                  text-field="name"
                  placeholder="Créancier"
                  @input="filterValue()"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      style="color: #b4b7bd; text-align: center"
                    >
                      -- Créancier --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <!-- select Personnel Concerné end -->

            <!-- select Association start -->
            <div class="col" v-if="showAssociationFilter">
              <b-form-group>
                <b-form-select
                  :options="associations"
                  id="caisse"
                  v-model="AssociationFilterValue"
                  value-field="name"
                  text-field="name"
                  placeholder="Association"
                  @input="filterValue()"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      style="color: #b4b7bd; text-align: center"
                    >
                      -- Association --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <!-- select Association end -->

            <!-- select Associé start -->
            <div class="col" v-if="showFsrFilter">
              <b-form-group>
                <b-form-select
                  :options="associes"
                  id="caisse"
                  v-model="FsrFilterValue"
                  value-field="name"
                  text-field="name"
                  placeholder="Associé"
                  @input="filterValue()"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      style="color: #b4b7bd; text-align: center"
                    >
                      -- Associé --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <!-- select Associé end -->

            <!-- select Compte/Caisse start -->
            <div class="col" v-if="showSourceFilter">
              <b-form-group>
                <b-form-select
                  :options="associes"
                  id="caisse"
                  v-model="CompteCaisseFilterValue"
                  value-field="name"
                  text-field="name"
                  placeholder="Compte/Caisse"
                  @input="filterValue()"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      style="color: #b4b7bd; text-align: center"
                    >
                      -- Compte/Caisse --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <!-- select Compte/Caisse end -->
            <!-- solde start -->
            <div class="col">
              <b-input-group prepend="Solde">
                <b-form-input
                  v-model="soldeCompteTiers"
                  type="text"
                  disabled
                  placeholder="Solde"
                >
                </b-form-input>
              </b-input-group>
            </div>
            <!-- solde end -->
          </div>

          <!-- table start -->
          <b-table
            v-model="excelJournalCaisse"
            id="my-table"
            responsive
            :fields="headers"
            :filter="filter"
            :filter-function="filterFunction"
            :items="operations"
            :per-page="perPage"
            :current-page="currentPage"
            show-empty
          >
            <template #cell(entree)="data">
              {{
                data.item.entree
                  ? $thousandSeparator(data.item.entree)
                  : "-----"
              }}
            </template>

            <template #cell(sortie)="data">
              {{
                data.item.sortie
                  ? $thousandSeparator(data.item.sortie)
                  : "-----"
              }}
            </template>

            <template #cell(solde)="data">
              {{ data.item.solde ? $thousandSeparator(data.item.solde) : 0 }}
            </template>

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>

            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>
              <span class="text-nowrap ml-2">  1 - {{perPage}} sur {{ totalRows }} </span>
            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              class="pagination"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>
      </b-card>
    </div>

    <!-- moal reglement -->
    <b-modal
      size="lg"
      id="relement-modal"
      content-class="shadow"
      v-model="dialogReglement"
      :ok-disabled="isOperating"
      no-close-on-backdrop
      title="Effectuer un règlement"
      hide-footer
      centered
    >
      <validation-observer ref="reglementForm">
        <b-form ref="reglementForm" @submit.stop.prevent="doReglement">
          <b-row class="formRow">
            <div class="col">
              <validation-provider
                #default="{ errors }"
                name="type de caisse"
                rules="required"
              >
                <label for="type_caisse">Type de caisse</label>
                <b-form-select
                  :disabled="compte.libelle == 'Frais'"
                  id="type_caisse"
                  v-model="reglementForm.type_caisse"
                  :options="regementTypes"
                  :state="errors.length > 0 ? false : null"
                  @input="checkAssocOrImp()"
                />
                <!-- <model-list-select
                  :list="regementTypes"
                  id="type_caisse"
                  v-model="reglementForm.type_caisse"
                  option-value="name"
                  option-text="name"
                  @input="checkAssocOrImp()"
                  :state="errors.length > 0 ? false : null"
                /> -->
                <small class="text-danger">{{
                  errors[0] ? errors[0] : erreurs.type_caisse
                }}</small>
              </validation-provider>
            </div>
            <b-form-group class="col" label="Caisse" label-for="caisse_fsr_imp">
              <validation-provider
                #default="{ errors }"
                name="caisse_fsr_imp"
                rules="required"
              >
                <model-list-select
                  :list="reglementCaisseList"
                  id="caisse_fsr_imp"
                  v-model="reglementForm.caisse_ass_imp"
                  option-value="id"
                  option-text="name"
                  :state="errors.length > 0 ? false : null"
                />

                <small class="text-danger">{{
                  errors[0] ? errors[0] : erreurs.caisse_fsr_imp
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>
          <b-row>
            <div class="col">
              <validation-provider
                #default="{ errors }"
                name="banque"
                rules="required"
              >
                <label for="banque">Banque</label>
                <model-list-select
                  :list="accountsListCP"
                  id="banque"
                  v-model="reglementForm.compteCP"
                  option-value="id"
                  option-text="libelle"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? errors[0] : erreurs.banque
                }}</small>
              </validation-provider>
            </div>
            <div class="col">
              <b-form-group label="Montant" label-for="amount">
                <validation-provider
                  #default="{ errors }"
                  name="amount"
                  rules="required"
                >
                  <b-input-group :append="compte.devise">
                    <cleave
                      class="form-control"
                      :raw="false"
                      id="prix_vente"
                      :options="numberFormat"
                      v-model="reglementForm.amount"
                      placeholder="0"
                      @change="checkReglementLib()"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : erreurs.montant
                  }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-row>
          <b-row>
            <div class="col">
              <b-form-group label="Libellé" label-for="libelle">
                <validation-provider
                  #default="{ errors }"
                  name="libelle"
                  rules="required"
                >
                  <b-form-input
                    id="libelle"
                    type="text"
                    v-model="reglementForm.libelle"
                    placeholder="Libelle"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : erreurs.libelle
                  }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-row>

          <b-form-group class="mb-0">
            <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="resetReglement()"
            >
              Annuler
            </b-button>

            <b-button
              v-if="etatButtonReglement"
              variant="primary"
              class="mt-2"
              @click="doReglement"
            >
              Enregistrer
            </b-button>

            <b-button v-else variant="primary" class="mt-2">
              <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
              ></b-icon-arrow-clockwise>
              En cours
            </b-button>
          </b-form-group>
        </b-form>
      </validation-observer>
      <!-- form end -->
    </b-modal>

    <!-- modal retrait  -->
    <b-modal
      id="retrait-modal"
      v-model="dialogRetrait"
      :ok-disabled="isOperating"
      title="Effectuer un retrait associé"
      size="lg"
      content-class="shadow"
      hide-footer
      no-close-on-backdrop
      centered
    >
      <!-- form start -->
      <validation-observer ref="retraitForm">
        <b-form @submit.stop.prevent="doRetrait">
          <b-row>
            <b-col class="col-12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="associe"
                  rules="required"
                >
                  <label for="associe">Retiré par</label>
                  <v-select
                    :options="AssociationAssocies"
                    id="associe"
                    v-model="retraitForm.retire_par"
                    label="name"
                    value="id"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col class="col-12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="banque"
                  rules="required"
                >
                  <label for="libelle">Banque</label>
                  <model-list-select
                    :list="accountsListCP"
                    id="banque"
                    v-model="retraitForm.compteCP"
                    option-value="id"
                    option-text="libelle"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : erreurs.banque
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
          </b-row>

          <b-row>
            <b-col class="col-12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="montant"
                  rules="required"
                >
                  <label for="montant">Montant</label>
                  <b-input-group :append="compte.devise">
                    <cleave
                      class="form-control"
                      :raw="false"
                      :options="numberFormat"
                      v-model="retraitForm.montant"
                      placeholder="0"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : erreurs.montant
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="col-12">
              <b-form-group>
                <validation-provider name="observation">
                  <label for="observation">Observation</label>
                  <b-form-input
                    v-model="retraitForm.observation"
                    type="text"
                    placeholder="Observation"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group class="mb-0">
            <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="resetRetrait()"
            >
              Annuler
            </b-button>

            <b-button
              v-if="etatButtonRetrait"
              variant="primary"
              class="mt-2"
              @click="doRetrait"
            >
              Enregistrer
            </b-button>

            <b-button v-else variant="primary" class="mt-2">
              <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
              ></b-icon-arrow-clockwise>
              En cours
            </b-button>
          </b-form-group>
        </b-form>
      </validation-observer>
      <!-- form end -->
    </b-modal>
    <!-- modal retrait end-->

    <!-- modal transfert  -->
    <b-modal
      id="transfert-modal"
      v-model="dialogTransfert"
      :ok-disabled="isOperating"
      title="Effectuer un transfert"
      size="lg"
      content-class="shadow"
      no-close-on-backdrop
      hide-footer
      centered
    >
      <!-- form start -->
      <validation-observer ref="tranfertForm">
        <b-form @submit.stop.prevent="doTransfert">
          <b-row>
            <b-col class="col-6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="operation_type"
                  rules="required"
                >
                  <label for="ci">Type d'opération</label>
                  <b-form-select
                    id="Type_opration"
                    v-model="transfertForm.operation_type"
                    placeholder="Type d'opération"
                    @change="checkOperationType('transfert')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-select-option selected value="entree">
                      Entrée caisse
                    </b-form-select-option>
                    <b-form-select-option value="sortie">
                      Sortie caisse
                    </b-form-select-option>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col class="col-6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="banque"
                  rules="required"
                >
                  <label for="libelle">Banque</label>
                  <model-list-select
                    :isDisabled="disableCP"
                    :list="accountsListCP"
                    id="banque"
                    v-model="transfertForm.compteCP"
                    option-value="id"
                    option-text="libelle"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : erreurs.banque
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="association"
                  rules="required"
                >
                  <label for="libelle">Association</label>

                  <model-list-select
                    :list="associationsOfAccountAndCaisse"
                    id="compte"
                    v-model="transfertForm.association"
                    option-value="id"
                    option-text="name"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col class="col-6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="libelle"
                  rules="required"
                >
                  <label for="libelle">Libellé</label>
                  <b-form-input
                    v-model="transfertForm.operation_libelle"
                    placeholder="Libellé"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : erreurs.banque
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="operation_montant"
                  rules="required"
                >
                  <label for="operation_montant">Montant</label>
                  <b-input-group :append="compte.devise">
                    <cleave
                      class="form-control"
                      :raw="false"
                      id="montant"
                      placeholder="Montant"
                      :options="numberFormat"
                      v-model="transfertForm.operation_montant"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : erreurs.operation_montant
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col class="col-6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="conversion_montant"
                  rules="required"
                >
                  <label for="conversion_montant">Conversion</label>
                  <cleave
                    class="form-control"
                    :raw="false"
                    id="conversion"
                    placeholder="0"
                    :disabled="isPrincipaleDevise"
                    :options="numberFormat"
                    v-model="transfertForm.conversion_montant"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : erreurs.conversion_montant
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group class="mb-0">
            <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2 mr-1"
              @click="resetTransfert()"
            >
              Annuler
            </b-button>

            <b-button
              v-if="etatButtonTransfert"
              variant="primary"
              class="mt-2"
              @click="doTransfert"
            >
              Enregistrer
            </b-button>

            <b-button v-else variant="primary" class="mt-2">
              <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
              ></b-icon-arrow-clockwise>
              En cours
            </b-button>
          </b-form-group>
        </b-form>
      </validation-observer>
      <!-- form end -->
    </b-modal>
    <!-- modal transfert end-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueSweetalert2 from "vue-sweetalert2";
import store from "@/store/index";
import { ValidationProvider, ValidationObserver, validate } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BForm,
  BModal,
  BButton,
  BIconPlus,
  BCardText,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormDatepicker,
  BIconCircleFill,
  BIconBuilding,
  BIconLayers,
  BIconOption,
  BIconFile,
  BTooltip,
  BIconCheckCircleFill,
  BIconPrinterFill,
  BIconArrowClockwise,
  BIconCalendarEvent,
  BInputGroupAppend,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
} from "bootstrap-vue";
import { ModelListSelect } from "vue-search-select";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";

export default {
  components: {
    VueSweetalert2,
    Cleave,
    ModelListSelect,
    VueSweetalert2,
    VueGoodTable,
    BCardCode,
    BPagination,
    BFormSelect,
    BInputGroupPrepend,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BTooltip,
    BFormDatepicker,
    BIconCircleFill,
    BIconBuilding,
    BIconLayers,
    BIconOption,
    BIconFile,
    BIconPrinterFill,
    BIconCheckCircleFill,
    BIconCalendarEvent,
    BFormSelectOption,
    BIconArrowClockwise,
    BIconFileEarmarkArrowDownFill,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      etatButtonTransfert: true,
      etatButtonRetrait: true,
      etatButtonReglement: true,
      otherHeaders: [
        {
          label: "Date de l'opération",
          key: "created_at",
        },
        { label: "Libellé", key: "libelle" },
        {
          label: "Association",
          key: "compte_tiers",
          filter: this.CaisseCoFilter,
        },
        { label: "Entrée", key: "entree" },
        { label: "Sortie", key: "sortie" },
        { label: "Solde", key: "solde" },
      ],

      accountsListCP: [],
      disableCP: true,
      creanciers: [
        {
          name: "caisse",
        },
      ],
      devises: [],
      devPrincipale: {},
      hidden: true,
      hiddenLoader: false,
      dateFormatOptions: { day: "numeric", month: "long", year: "numeric" },
      pageOptions: [10, 25, 50, 100, { value: 1000000, text: "Tout" }],

      perPage: 10,
      regementTypes: ["Association", "Importateur"],
      currentPage: 1,
      filter: "",
      FraisHeaders: [
        {
          label: "Date de l'opération",
          key: "created_at",
        },
        { label: "Libellé", key: "libelle" },
        {
          label: "Association / Importateur",
          key: "compte_tiers",
          filter: this.CaisseCoFilter,
        },
        { label: "Entrée", key: "entree" },
        { label: "Sortie", key: "sortie" },
        { label: "Solde", key: "solde" },
      ],

      CIHeaders: [
        {
          label: "Date de l'opération",
          key: "created_at",
        },
        { label: "Libellé", key: "libelle" },
        {
          label: "Association / Importateur",
          key: "compte_tiers",
          filter: this.CaisseCoFilter,
        },
        // { label: 'Société', key: 'societe', filter: this.SocieteFilter },
        { label: "Entrée", key: "entree" },
        { label: "Sortie", key: "sortie" },
        { label: "Solde", key: "solde" },
      ],

      avancePersonnelHeader: [
        {
          label: "Date de l'opération",
          key: "created_at",
        },
        { label: "Libellé", key: "libelle" },
        {
          label: "Personnel",
          key: "compte_tiers",
          filter: this.MembresFilter,
        },
        { label: "Créancier", key: "source" },
        { label: "Entrée", key: "entree" },
        { label: "Sortie", key: "sortie" },
        { label: "Solde", key: "solde" },
      ],

      retraitFsrHeader: [
        {
          label: "Date de l'opération",
          key: "created_at",
        },
        { label: "Libellé", key: "libelle" },
        {
          label: "Association",
          key: "compte_tiers",
          filter: this.CompteCaisseFilter,
        },
        { label: "Retiré par", key: "retirer_par", filter: this.FsrFilter },
        // { label: "Source", key: "source", filter: this.SourceFilter },
        { label: "Montant", key: "entree" },
      ],

      search: "",
      dialogRetrait: false,
      startDateFilterValue: null,
      endDateFilterValue: null,
      CaisseCoFilterValue: null,
      SocieteFilterValue: null,
      CompteCaisseFilterValue: null,
      MembresFilterValue: null,
      CreancierilterValue: null,
      SourceFilterValue: null,
      FsrFilterValue: null,
      AssociationFilterValue: null,
      entete: "",
      existSociete: false,
      importateurs: [],
      caisse_concernee: [],
      reglementCaisseList: [],
      associations: [],
      associationsOfAccount: [],
      associationsOfAccountAndCaisse: [],
      associes: [],
      societes: [],
      operations: [],
      excelJournalCaisse: null,
      operationsList: [],
      comptes: [],
      numberFormat: {
        numeral: true,
        delimiter: " ",
        numeralThousandsGroupStyle: "thousand",
      },
      membres: [],
      sources: [
        { text: "Tout", value: null },
        { text: "Caisse", value: "caisse" },
        { text: "Compte", value: "compte" },
      ],

      valid: true,
      erreurs: {},
      dialogTransfert: false,
      dialogReglement: false,
      dialogDelete: false,
      isOperating: false,
      validRetraitFsr: false,
      validReglement: false,
      menu1: false,
      menu2: false,

      editedIndex: -1,
      transfertForm: {},
      retraitForm: {},
      reglementForm: {},
      message: "",

      defaultItem: {},
      snackbar: false,

      compte_tiers: true,
      compte: {},

      startDateFilterValue: null,
      endDateFilterValue: null,
      errorValueStart: "",

      isImportateur: true,
      AssocieComptes: [],
      AssociesAndImporter: [],
      AssociationAssocies: [],
      AssocieListOfAssociations: [],
      AssociationComptesAssocies: [],
      filter: "",
      filterVal: null,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),

    totalRows() {
      // Set the initial number of items
      return this.operations.length;
    },
    retraitPossible() {
      if (this.compte.author_type == "associe") {
        return true;
      } else return false;
    },

    comptesFsr() {
      let list = [];
      let fsrPrinc = this.associes.find(
        ({ fournisseur_principal }) => fournisseur_principal === 1
      );
      let thisFsr = this.associes.find(
        ({ name }) => name === this.compte.libelle
      );
      if (fsrPrinc) list.push(fsrPrinc);
      if (thisFsr) list.push(thisFsr);
      return list;
    },

    transfertPossible() {
      switch (this.compte.libelle) {
        case "Prêt Employé/Partenaire":
          return false;
          break;

        case "Retrait Associés":
          return false;
          break;

        case "Carte Importateur":
          return false;
          break;

        case "Frais":
          return false;
          break;

        case "Generation Facture":
          return false;
          break;

        default:
          return true;
          break;
      }
    },

    reglementPossible() {
      switch (this.compte.libelle) {
        case "Carte Importateur":
          return true;
          break;

        case "Frais":
          return true;
          break;

        case "Generation Facture":
          return true;
          break;

        default:
          return false;
          break;
      }
    },

    headers() {
      switch (this.compte.libelle) {
        case "Prêt Employé/Partenaire":
          return this.avancePersonnelHeader;
          break;
        case "Manifeste Carte Importateur":
          return this.MCIHeader;
          break;
        case "Frais":
          return this.FraisHeaders;
          break;
        case "Carte Importateur":
          return this.CIHeaders;
          break;
        case "Retrait Associés":
          return this.retraitFsrHeader;
          break;

        case "Generation Facture":
          return this.CIHeaders;
          break;

        default:
          return this.otherHeaders;
          break;
      }
    },

    formTitle() {
      return this.editedIndex === -1 ? "Ajouter un compte" : "Modifier";
    },

    showSocieteFilter() {
      switch (this.compte.libelle) {
        case "Frais":
          return true;
          break;
        case "Carte Importateur":
          return true;
          break;
        case "Manifeste Carte Importateur":
          return true;
          break;

        default:
          return false;
          break;
      }
    },

    showCaisseFilter() {
      switch (this.compte.libelle) {
        case "Retrait Associés":
          return false;
          break;

        case "Prêt Employé/Partenaire":
          return false;
          break;

        default:
          return true;
          break;
      }
    },

    showFsrFilter() {
      switch (this.compte.libelle) {
        case "Retrait Associés":
          return true;
          break;

        default:
          return false;
          break;
      }
    },
    showAssociationFilter() {
      switch (this.compte.libelle) {
        case "Retrait Associés":
          return true;
          break;

        default:
          return false;
          break;
      }
    },

    showSourceFilter() {
      switch (this.compte.libelle) {
        case "Retrait Associés":
          return false;
          break;

        default:
          return false;
          break;
      }
    },

    showMembresFilter() {
      switch (this.compte.libelle) {
        case "Prêt Employé/Partenaire":
          return true;
          break;

        default:
          return false;
          break;
      }
    },

    showSoldeFilter() {
      return true;
    },

    options() {
      return {
        distractionFree: false,
        valueAsInteger: true,
        AllowNegative: false,
        autoDecimalMode: true,
        currency: null,
        precision: 0,
      };
    },

    taux() {
      let formatedTaux = 1;
      if (
        this.transfertForm.conversion_montant &&
        this.transfertForm.operation_montant
      ) {
        formatedTaux =
          this.transfertForm.conversion_montant /
          this.transfertForm.operation_montant;
      }
      return formatedTaux;
    },

    soldeCompteTiers() {
      let entree = 0;
      let sortie = 0;

      if (!this.operations) this.operations = [];
      let operations = this.operationsList;

      if (this.convert(this.startDateFilterValue)) {
        operations = operations.filter(
          (operation) =>
            this.toDate(operation.created_at) >=
            this.convert(this.startDateFilterValue)
        );
      }

      if (this.convert(this.endDateFilterValue)) {
        operations = operations.filter(
          (operation) =>
            this.toDate(operation.created_at) <=
            this.convert(this.endDateFilterValue)
        );
      }

      if (this.CaisseCoFilterValue) {
        // réglé
        operations = operations.filter(
          (operation) => operation.compte_tiers == this.CaisseCoFilterValue
        );
      }

      if (this.CompteCaisseFilterValue) {
        operations = operations.filter(
          (operation) => operation.compte_tiers == this.CompteCaisseFilterValue
        );
      }

      if (this.FsrFilterValue) {
        operations = operations.filter(
          (operation) => operation.retirer_par == this.FsrFilterValue
        );
      }

      if (this.SocieteFilterValue) {
        operations = operations.filter(
          (operation) => operation.societe == this.SocieteFilterValue
        );
      }

      if (this.MembresFilterValue) {
        operations = operations.filter(
          (operation) => operation.compte_tiers == this.MembresFilterValue
        );
      }

      if (this.SourceFilterValue) {
        operations = operations.filter(
          (operation) => operation.source == this.SourceFilterValue
        );
      }

      if (this.CreancierilterValue) {
        operations = operations.filter(
          (operation) => operation.source == this.CreancierilterValue
        );
      }

      if (operations.length > 0) {
        // let lastItem = operations[operations.length - 1];
        // lastItem.solde = lastItem.entree ? lastItem.entree : lastItem.sortie ;

        operations
          .slice()
          .reverse()
          .forEach((element, index) => {
            if (index == 0) {
              element.solde = element.entree ? element.entree : -element.sortie;
            } else if (index > 0) {
              if (element.entree) {
                element.solde =
                  operations[operations.length - index].solde + element.entree;
              } else if (element.sortie) {
                element.solde =
                  operations[operations.length - index].solde - element.sortie;
              } else {
                element.solde = operations[operations.length - index].solde + 0;
              }
            }
          });
      }

      operations.forEach((element) => {
        if (element.entree) entree += element.entree;
        if (element.sortie) sortie += element.sortie;
      });

      return this.$thousandSeparator(entree - sortie);
    },

    soldeCompteTiersPrint() {
      let entree = 0;
      let sortie = 0;

      if (!this.operations) this.operations = [];
      let operations = this.operations;

      if (this.CaisseCoFilterValue) {
        operations = operations.filter(
          (operation) => operation.compte_tiers == this.CaisseCoFilterValue
        );
      }

      if (this.CompteCaisseFilterValue) {
        operations = operations.filter(
          (operation) => operation.compte_tiers == this.CompteCaisseFilterValue
        );
      }

      if (this.FsrFilterValue) {
        operations = operations.filter(
          (operation) => operation.retirer_par == this.FsrFilterValue
        );
      }

      if (this.SocieteFilterValue) {
        operations = operations.filter(
          (operation) => operation.societe == this.SocieteFilterValue
        );
      }

      if (this.MembresFilterValue) {
        operations = operations.filter(
          (operation) => operation.compte_tiers == this.MembresFilterValue
        );
      }

      if (this.SourceFilterValue) {
        operations = operations.filter(
          (operation) => operation.source == this.SourceFilterValue
        );
      }

      operations.forEach((element) => {
        if (element.entree) entree += element.entree;
        if (element.sortie) sortie += element.sortie;
      });

      return this.$thousandSeparator(entree - sortie);
    },

    isPrincipaleDevise() {
      if (this.devPrincipale.name === this.compte.devise) {
        this.transfertForm.conversion_montant =
          this.transfertForm.operation_montant;
        return true;
      }
      return false;
    },
  },

  watch: {},

  async created() {
    await this.initialize();
    this.comptesData();
    this.societesData();
    this.associesData();

    this.getAssociations();
    this.importateursData();

    this.comptesCPData();
    this.getDevises();
  },

  methods: {
    filterRecherche(val) {
      if (val) {
        this.filter = val;
      } else {
        this.filter = " ";
      }

      this.filterVal = val;
    },
    filterValue(val) {
      this.filter = " ";
    },
    dateFilter(start, end, value) {
      this.filter = " ";
      if (!start && !end) {
        return true;
      } else if (start && !end) {
        return value >= start;
      } else if (!start && end) {
        return value <= end;
      }
      return value >= start && value <= end;
    },
    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, "-");
      var parts = dateStr.split("-");
      var date = new Date(parts[2], parts[1] - 1, parts[0]);
      return date;
    },
    filterFunction(row, DateFilter) {
      var start = null;
      var end = null;
      var value = new Date(this.toDate(row.created_at));

      if (this.startDateFilterValue) {
        var parts = this.startDateFilterValue.split("-");
        start = new Date(parts[0], parts[1] - 1, parts[2]);
      }

      if (this.endDateFilterValue) {
        var parts = this.endDateFilterValue.split("-");
        end = new Date(parts[0], parts[1] - 1, parts[2]);
      }

      if (
        this.filterVal != null &&
        row.libelle.toLowerCase().indexOf(this.filterVal.toLowerCase()) == -1 &&
        row.compte_tiers.toLowerCase().indexOf(this.filterVal.toLowerCase()) ==
          -1 &&
        this.CaisseCoFilterValue == null &&
        this.MembresFilterValue == null &&
        this.CreancierilterValue == null &&
        this.FsrFilterValue == null &&
        this.AssociationFilterValue == null &&
        this.CompteCaisseFilterValue == null
      ) {
        return false;
      }

      if (
        this.CaisseCoFilterValue != null &&
        this.CaisseCoFilterValue != row.compte_tiers
      ) {
        return false;
      } else if (
        this.MembresFilterValue != null &&
        this.MembresFilterValue != row.compte_tiers
      ) {
        return false;
      } else if (
        this.CreancierilterValue != null &&
        this.CreancierilterValue != row.source
      ) {
        return false;
      } else if (
        this.FsrFilterValue != null &&
        this.FsrFilterValue != row.retirer_par
      ) {
        return false;
      } else if (
        this.AssociationFilterValue != null &&
        this.AssociationFilterValue != row.compte_tiers
      ) {
        return false;
      } else if (
        this.CompteCaisseFilterValue != null &&
        this.CompteCaisseFilterValue != row.compte_tiers
      ) {
        return false;
      } else {
        return this.dateFilter(start, end, value);
      }
    },
    getAssociations() {
      this.$http.get("/associations").then((response) => {
        this.associations = response.data.data;

        // if (
        //   this.compte.libelle == "Carte Importateur" ||
        //   this.compte.libelle == "Generation Facture" ||
        //   this.compte.libelle == "Frais"
        // ) {
        this.caisse_concernee.push({
          id: "caisse",
          value: "caisse",
          text: "Caisse",
          name: "Caisse",
        });
        response.data.data.forEach((element) => {
          this.caisse_concernee.push(element);
          this.creanciers.push(element);
        });
        // }
      });
    },

    getDevises() {
      this.$http.get("/devises").then((response) => {
        this.devises = response.data.data;
        this.devPrincipale = response.data.data.find(
          ({ devise_principale }) => devise_principale === 1
        );
      });
    },
    resetModal() {},
    async comptesData() {
      await this.$http.get("/comptes").then((response) => {
        this.comptes = response.data.data;
      });
    },

    comptesCPData() {
      this.$http.get("/caisse-principale-resources").then((response) => {
        this.accountsListCP = response.data.data;
      });
    },

    isNotAdmin(group) {
      let table = ["Associé", "Chargeur", "Importateur"];
      if (table.includes(group)) {
        return true;
      } else {
        return false;
      }
    },

    isNotValid(montant) {
      if (montant) {
        // let FormatedMontant = parse(montant ? montant : "0", this.options);
        if (montant > 0) {
          return false;
        }
        return true;
      }
      return true;
    },

    //COMPOSITION DU LIBELLE POUR REGLEMENT
    checkReglementLib() {
      let ReglementPour = this.compte.libelle + " ";
      let ReglementMontant = "";
      let ReglementPar = null;

      if (this.reglementForm.associe_importateur) {
        ReglementPar = "par " + this.reglementForm.associe_importateur + " ";
      } else {
        ReglementPar = "";
      }

      // if (this.reglementForm.amount) {
      //   ReglementMontant = "de " + this.reglementForm.amount + " ";
      // } else {
      //   ReglementMontant = "";
      // }
      return (this.reglementForm.libelle =
        "Reglement pour " +
        ReglementPour +
        ReglementMontant +
        ReglementPar +
        ".");
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    checkAssocOrImp() {
      this.reglementCaisseList = [];
      if (this.reglementForm.type_caisse == "Importateur") {
        this.reglementCaisseList = this.importateurs;
      } else if (this.reglementForm.type_caisse == "Association") {
        this.reglementCaisseList = this.associations;
      }
      // let toFind = this.AssociesAndImporter.find(
      //   (_item) => _item.name === this.reglementForm.associe_importateur
      // );

      // if (toFind) {
      //   let isAccos = this.associes.find(
      //     (_assoc) => _assoc.name === toFind.name
      //   );
      //   if (isAccos) {
      //     this.isImportateur = false;
      //     this.reglementForm.associe_importateur_id = "";
      //     this.AssocieComptes = isAccos.comptes;
      //     this.AssocieListOfAssociations = isAccos.associations;
      //   } else {
      //     this.isImportateur = true;
      //     this.reglementForm.associe_importateur_id = toFind.id;
      //     this.reglementForm.caisse_ass_imp = "";
      //     this.AssocieComptes = [];
      //     this.AssocieListOfAssociations = [];
      //     this.reglementForm.compte_tiers = "";
      //   }
      // }
    },

    async importateursData() {
      await this.$http.get("/importateurs").then((response) => {
        this.importateurs = response.data.data;
        if (
          this.compte.libelle == "Carte Importateur" ||
          this.compte.libelle == "Generation Facture"
        ) {
          response.data.data.forEach((element) => {
            this.caisse_concernee.push(element);
            this.AssociesAndImporter.push(element);
          });
        }
        // if (this.compte.libelle != "Frais") {
        //   response.data.data.forEach((element) => {
        //       this.AssociesAndImporter.push(element);
        //   });
        // }
      });
    },

    async MembresData() {
      this.$http.get("/gestion-personnel/personnels").then((response) => {
        this.membres = response.data.data;
      });
    },

    async associesData() {
      await this.$http.get("/associes").then((response) => {
        this.associes = response.data.data;
        response.data.data.forEach((element) => {
          this.AssociesAndImporter.push(element);
        });
      });
    },

    async societesData() {
      await this.$http.get("/societes").then((response) => {
        this.societes = response.data.data;
      });
    },

    showReport(action) {
      const filters = {
        compte_tiers: (compte_tiers) => {
          if (this.CaisseCoFilterValue) {
            return compte_tiers === this.CaisseCoFilterValue;
          } else if (this.MembresFilterValue) {
            return compte_tiers === this.MembresFilterValue;
          } else if (this.CompteCaisseFilterValue) {
            return compte_tiers === this.CompteCaisseFilterValue;
          } else return true;
        },

        source: (source) => {
          if (this.SourceFilterValue) return source === this.SourceFilterValue;
          else return true;
        },

        retirer_par: (retirer_par) => {
          if (this.FsrFilterValue) return retirer_par === this.FsrFilterValue;
          else return true;
        },

        created_at: (created_at) => {
          let start = this.convert(this.startDateFilterValue);
          let end = this.convert(this.endDateFilterValue);
          created_at = this.toDate(created_at);
          if (!start && !end) {
            return true;
          } else if (start && !end) {
            return created_at >= start;
          } else if (!start && end) {
            return created_at <= end;
          }
          return created_at >= start && created_at <= end;
        },
      };
      let result = this.globalFilter(this.operations, filters);

      if (action == "print") {
        this.print(result);
      } else {
        return result;
      }
    },
    globalFilter(array, filters) {
      if (!array) array = [];
      array.forEach((element) => {
        element["created_at"] = this.convertg(element["created_at"]);
      });
      const filterKeys = Object.keys(filters);
      return array.filter((item) => {
        // je valide tous les critères de tri
        return filterKeys.every((key) => {
          // ignorer les predicats qui ne sont pas des fonctions
          if (typeof filters[key] !== "function") return true;
          return filters[key](item[key]);
        });
      });
    },
    convertg(date) {
      if (date) {
        date = date.replace(/\//g, "-");
      }
      return date;
    },

    print(data) {
      let toPrint = {
        type: "Compte",
        concerne: this.compte.libelle,
        tiersConcerne: this.MembresFilterValue
          ? this.MembresFilterValue
          : this.tiersConcerne(),
        headers: this.headers,
        // showSoldeColumn: false,
        showSoldeColumn: true,
        showMontantColumn:
          this.compte.libelle == "Prêt Employé/Partenaire" ? true : false,
        journal: data,
        periodeDu: this.convert(this.startDateFilterValue),
        periodeAu: this.convert(this.endDateFilterValue),
        solde: this.$thousandSeparator(this.soldeCompteTiersPrint),
        // solde: this.$thousandSeparator(this.compte.solde),
        soldeDebut: this.$thousandSeparator(this.soldeDebut(data)),

        source: this.SourceFilterValue ? this.SourceFilterValue : null,
        compe_caisse: this.CompteCaisseFilterValue
          ? this.CompteCaisseFilterValue
          : null,
        fournisseur:
          this.FsrFilterValue &&
          this.compte.libelle == "Prêt Employé/Partenaire"
            ? this.FsrFilterValue
            : null,
      };
      this.$router.push({
        name: "compte-print",
        params: { data: toPrint },
      });
    },

    tiersConcerne() {
      let msg = "";
      if (this.SocieteFilterValue)
        msg += "Societé : " + this.SocieteFilterValue + ", ";
      if (this.CaisseCoFilterValue)
        msg += "Tiers : " + this.CaisseCoFilterValue;
      return msg;
    },

    soldeDebut(data) {
      let start = this.convert(this.startDateFilterValue);
      let solde = 0;
      if (!start) return 0;
      else {
        if (data[data.length]) {
          return data[data.length].solde;
        }
        return 0;
      }
    },

    checkOperationType(operation) {
      switch (operation) {
        case "transfert":
          if (this.transfertForm.operation_type == "entree") {
            let account = this.accountsListCP.find(
              ({ libelle }) => libelle === "Coffre-fort"
            );
            this.transfertForm.compteCP = null;
            this.transfertForm.compteCP = account.id;
            this.disableCP = true;
          } else {
            this.disableCP = false;
          }

          break;

        default:
          break;
      }
    },

    async initialize() {
      let id = this.$router.currentRoute.params.id;
      await this.$http.get("/archive/comptes/" + id).then((response) => {
        this.compte = response.data.data;
        this.associationsOfAccount = this.compte.associations
          ? this.compte.associations
          : [];
        this.associationsOfAccountAndCaisse = [];
        this.associationsOfAccount.forEach((element) => {
          if (element.name) {
            let obj = {
              id: element.id,
              value: element.id,
              text: element.name,
              name: element.name,
            };
            this.associationsOfAccountAndCaisse.push(obj);
          }
        });
        this.operations = this.compte.journal_comptes;
        this.operationsList = this.compte.journal_comptes;
        this.hiddenLoader = true;
        //////////////////////////////////////
        if (this.compte.libelle == "Prêt Employé/Partenaire") {
          this.MembresData();
        }
      });
    },

    convert(date) {
      if (date) {
        date = new Date(date);
      }
      return this.reformatDate(date);
    },

    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, "-");
      var parts = dateStr.split("-");
      var date = new Date(parts[2], parts[1] - 1, parts[0]);
      return this.reformatDate(date);
    },

    reformatDate(date) {
      if (date) {
        let annee = date.getFullYear();
        let mois = ("0" + (date.getMonth() + 1)).slice(-2);
        let jour = ("0" + date.getDate()).slice(-2);
        date = annee + "-" + mois + "-" + jour;
        return date.toString();
      }
      return Date.parse(date);
    },

    CaisseCoFilter(value) {
      if (!this.CaisseCoFilterValue) {
        return true;
      }
      return value === this.CaisseCoFilterValue;
    },

    SocieteFilter(value) {
      if (!this.SocieteFilterValue) {
        return true;
      }
      return value === this.SocieteFilterValue;
    },

    MembresFilter(value) {
      if (!this.MembresFilterValue) {
        return true;
      }
      return value === this.MembresFilterValue;
    },

    SourceFilter(value) {
      if (!this.SourceFilterValue) {
        return true;
      }
      return value === this.SourceFilterValue;
    },

    CompteCaisseFilter(value) {
      if (!this.CompteCaisseFilterValue) {
        return true;
      }
      return value === this.CompteCaisseFilterValue;
    },

    FsrFilter(value) {
      if (!this.FsrFilterValue) {
        return true;
      }
      return value === this.FsrFilterValue;
    },

    resetTransfert() {
      this.dialogTransfert = false;
      this.transfertForm.operation_type = "";
      this.transfertForm.association = "";
      this.transfertForm.operation_compte_tiers = "";
      this.transfertForm.operation_libelle = "";
      this.transfertForm.compteCP = "";
      this.transfertForm.conversion_montant = 0;
      this.transfertForm.montant = 0;
      this.transfertForm.operation_montant = 0;
    },

    resetReglement() {
      this.dialogReglement = false;
      this.reglementForm.amount = "";
      this.reglementForm.caisse_ass_imp = "";
      this.reglementForm.compteCP = "";
      this.reglementForm.compte_concerne = "";
      this.reglementForm.associe_importateur = "";
      this.reglementForm.libelle = "";
    },

    resetRetrait() {
      this.dialogRetrait = false;
      this.retraitForm.retire_par = null;
      this.retraitForm.montant = 0;
      this.retraitForm.observation = "";
    },

    openReglement() {
      this.dialogReglement = true;
      this.retraitForm.compte = this.compte.libelle;
      let account = this.accountsListCP.find(
        ({ libelle }) => libelle === "Coffre-fort"
      );
      this.reglementForm.compteCP = account.id;
      this.reglementForm.type_caisse = "Association";
      this.reglementCaisseList = this.associations;
    },

    openTransfert() {
      this.dialogTransfert = true;
      this.transfertForm.operation_type = "entree";
      let account = this.accountsListCP.find(
        ({ libelle }) => libelle === "Coffre-fort"
      );
      this.transfertForm.compteCP = account.id;
    },

    openRetrait() {
      this.dialogRetrait = true;
      let account = this.accountsListCP.find(
        ({ libelle }) => libelle === "Coffre-fort"
      );
      this.retraitForm.compteCP = account.id;
      let selectedAssociation = this.associations.find(
        ({ id }) => id === this.compte.author_association
      );
      this.AssociationAssocies = selectedAssociation.associes;
    },

    closeReglement() {
      this.dialogReglement = false;
      this.$refs.formReglement.reset();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.transfertForm = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    echange(a, b) {
      let c = a ? a : null;
      a = b ? b : null;
      b = c;
    },

    async saveTransfert() {
      this.isOperating = true;
      this.transfertForm.operation_montant = this.$thousandFormater(
        this.transfertForm.operation_montant
      );
      this.transfertForm.conversion_montant = this.$thousandFormater(
        this.transfertForm.conversion_montant
      );

      this.transfertForm.compte_type =
        window.sessionStorage.getItem("compte_id");

      if (this.transfertForm.association == "caisse") {
        this.transfertForm.association == null;
      }

      console.log(this.transfertForm);

      //     let a = this.comptes.find(
      //       ({ libelle }) =>
      //         libelle === this.transfertForm.operation_compte_tiers
      //     );
      //     if (a) this.transfertForm.operation_compte_tiers = a.id;
      //     else {
      //       this.transfertForm.operation_compte_tiers = "";
      //     }
      //
      // }

      this.etatButtonTransfert = false;
      let thirdAccount = this.associationsOfAccountAndCaisse.find(
        (_assoc) => _assoc.id == this.transfertForm.association
      );

      await this.$http
        .post("/journal-comptes", this.transfertForm)
        .then((result) => {
          this.isOperating = false;
          if (result.data.success == false) {
            this.etatButtonTransfert = true;
            this.message = result.data.errors.name[0];
            // this.$refs.form.reset();
          } else {
            this.etatButtonTransfert = true;
            this.showToast(
              "Nouvelle entrée enregistrée avec succès",
              "success"
            );
            let toPrint = result.data.data;

            toPrint.compte = this.compte.libelle;
            toPrint.caisse = thirdAccount ? thirdAccount.name : "-----";
            this.goPrint("transfert", toPrint);
            this.initialize();
            this.resetTransfert();
          }
        })
        .catch((err) => {
          this.etatButtonTransfert = true;
          this.isOperating = false;
          this.showToast(err.response.data.message, "danger");
        });
    },

    async saveRetrait() {
      this.isOperating = true;
      this.retraitForm.caisse = window.sessionStorage.getItem("compte_id");
      // this.retraitForm.montant = parse(
      //   this.retraitForm.montant ? this.retraitForm.montant : "0",
      //   this.options
      // ).toString();

      // let a = this.associes.find(
      //   ({ name }) => name === this.retraitForm.compte
      // );

      // if (a) this.retraitForm.retire_par = a.id;

      this.retraitForm.associe = this.retraitForm.retire_par.id;
      this.retraitForm.montant = this.$thousandFormater(
        this.retraitForm.montant
      );

      this.etatButtonRetrait = false;

      this.$http
        .post("/retrait-associe-compte", this.retraitForm)
        .then((result) => {
          this.isOperating = false;
          if (result.data.success == false) {
            this.etatButtonRetrait = true;
            let error = result.data.message;
            error = error[Object.keys(error)[0]][0];
            this.showToast(error, "danger");
          } else {
            this.etatButtonRetrait = true;
            this.showToast("Nouveau retrait enregistré avec succès", "success");
            this.initialize();
            this.resetRetrait();
          }
        })
        .catch((err) => {
          this.etatButtonRetrait = true;
          this.isOperating = false;
          this.showToast(err.response.data.message, "danger");
        });
    },

    saveReglement() {
      // const valid = this.$refs.reglementForm.checkValidity();
      // if (valid) {
      this.reglementForm.amount = this.$thousandFormater(
        this.reglementForm.amount
      );
      // this.reglementForm.associe_importateur = this.AssociesAndImporter.find(
      //   ({ name }) => name === this.reglementForm.associe_importateur
      // ).id;
      this.isOperating = true;
      this.reglementForm.societe = this.societes[0].id;
      this.reglementForm.compte_concerne = this.compte.id;

      this.etatButtonReglement = false;

      this.$http
        .post("/associes-importateurs/reglement", this.reglementForm)
        .then((result) => {
          this.isOperating = false;
          if (!result.data.success) {
            this.etatButtonReglement = true;
            let error = result.data.message;
            error = error[Object.keys(error)[0]][0];
            this.showToast(error, "danger");
          } else {
            this.etatButtonReglement = true;
            this.showToast(
              "Nouveau règlement enregistré avec succès",
              "success"
            );
            this.initialize();
            this.resetReglement();
          }
        })
        .catch((err) => {
          this.etatButtonReglement = true;
          this.isOperating = false;
          this.showToast(err.response.data.message, "danger");
        });
      // }
    },

    doRetrait(bvModalEvt) {
      bvModalEvt.preventDefault();

      let associate = this.retraitForm.retire_par
        ? this.retraitForm.retire_par
        : { name: "celui qui est choisi" };
      this.message =
        "Confirmez-vous le retrait de " +
        this.$thousandSeparator(this.retraitForm.montant) +
        " " +
        this.compte.devise +
        " par " +
        associate.name +
        " ?";
      this.$bvModal
        .msgBoxConfirm(this.message, {
          title: "Retrait associé",
          okVariant: "success",
          cancelVariant: "outline-secondary",
          okTitle: "Confirmer",
          cancelTitle: "Annuler",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.saveRetrait();
          } else {
            this.dialogRetrait = true;
          }
        });
    },

    doReglement(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (
        this.reglementForm.caisse_ass_imp &&
        this.reglementForm.amount &&
        this.reglementForm.libelle
      ) {
        if (this.reglementForm.type_caisse == "Importateur") {
          this.reglementCaisseList = this.importateurs;
          var toFind = this.importateurs.find(
            (_item) => _item.id === this.reglementForm.caisse_ass_imp
          );
        } else {
          this.reglementCaisseList = this.associations;
          var toFind = this.associations.find(
            (_item) => _item.id === this.reglementForm.caisse_ass_imp
          );
        }

        let message =
          "Confirmez-vous le règlement de " +
          this.$thousandSeparator(this.reglementForm.amount) +
          " " +
          this.compte.devise +
          " par " +
          toFind.name +
          " au profit de ce compte ?";

        this.$bvModal
          .msgBoxConfirm(message, {
            title: "Règlement",
            okVariant: "success",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.saveReglement();
            } else {
              this.dialogReglement = true;
            }
          });
      } else {
        this.showToast("Veuillez remplir tout le formulaire", "danger");
      }
    },

    doTransfert(bvModalEvt) {
      bvModalEvt.preventDefault();

      let dep_objet = this.compte.libelle;
      let thirdAccount = this.associationsOfAccountAndCaisse.find(
        (_assoc) => _assoc.id == this.transfertForm.association
      );

      if (thirdAccount) {
        thirdAccount = thirdAccount.name;
        let lib = this.transfertForm.operation_libelle;
        switch (this.transfertForm.operation_type) {
          case "sortie":
            this.message =
              "Confirmez-vous le transfert de " +
              this.transfertForm.operation_montant +
              " " +
              this.compte.devise +
              " de " +
              (thirdAccount == "Caisse"
                ? "la Caisse"
                : "la caisse :" + thirdAccount) +
              " vers le compte : " +
              dep_objet +
              " pour : " +
              lib +
              " ?";
            break;
          case "entree":
            this.message =
              "Confirmez-vous le transfert de " +
              this.transfertForm.operation_montant +
              " " +
              this.compte.devise +
              " du compte : " +
              dep_objet +
              " vers " +
              (thirdAccount == "Caisse"
                ? "la Caisse"
                : "la caisse :" + thirdAccount) +
              " pour : " +
              lib +
              " ?";
            break;
        }
        this.$bvModal
          .msgBoxConfirm(this.message, {
            title: "Transfert",
            okVariant: "success",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.saveTransfert();
            } else {
              this.dialogTransfert = true;
            }
          });
      } else {
        this.showToast("Veuillez séléctionner une caisse", "danger");
      }
    },

    goPrint(type, item) {
      let toPrint = {
        type: type,
        operation: item.entree ? "ENTREE" : "SORTIE",
        libelle: item.libelle,
        montant: item.entree ? item.entree : item.sortie,
        compte: item.compte,
        tiers: item.compte_tiers,
        societe: item.societe,
        societe_entete: this.entete,
        debiteur: item.entree ? item.compte : item.caisse,
        debite: item.entree ? item.caisse : item.compte,
        // societe:  item.operation_societe ? this.societes.find(({ id }) => id === item.operation_societe).name : null
      };

      let routeData = this.$router.resolve({
        name: "finances-bordereau",
        query: toPrint,
      });
      window.open(routeData.href, "_blank");
    },

    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers 6
      let data = [];
      let obj = {};

      switch (this.compte.libelle) {
        case "Prêt Employé/Partenaire":
          data = [];
          obj = {};

          this.excelJournalCaisse.forEach((element) => {
            obj = {
              "DATE OPERATION(S)": element.created_at,
              "LIBELLE(S)": element.libelle,
              "PERSONNEL(S)": element.compte_tiers,
              "CREANCIER(S)": element.solde ? element.solde : 0,
              "ENTRÉE(S)": element.entree ? element.entree : 0,
              "SORTIE(S)": element.sortie ? element.sortie : 0,
              "SOLDE(S)": element.solde ? element.solde : 0,
            };

            data.push(obj);
          });
          break;

        case "Retrait Associés":
          data = [];
          obj = {};

          this.excelJournalCaisse.forEach((element) => {
            obj = {
              "DATE OPERATION(S)": element.created_at,
              "LIBELLE(S)": element.libelle,
              "ASSOCIATION / IMPORTATEUR(S)": element.compte_tiers,
              "RETIRÉ PAR(S)": element.retirer_par,
              "MONTANT(S)": element.entree ? element.entree : 0,
            };

            data.push(obj);
          });
          break;

        default:
          data = [];
          obj = {};

          this.excelJournalCaisse.forEach((element) => {
            obj = {
              "DATE OPERATION(S)": element.created_at,
              "LIBELLE(S)": element.libelle,
              "ASSOCIATION / IMPORTATEUR(S)": element.compte_tiers,
              "ENTRÉE(S)": element.entree ? element.entree : 0,
              "SORTIE(S)": element.sortie ? element.sortie : 0,
              "SOLDE(S)": element.solde ? element.solde : 0,
            };

            data.push(obj);
          });
          break;
      }

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des opérations",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers 6
      let data = [];
      let obj = {};

      switch (this.compte.libelle) {
        case "Prêt Employé/Partenaire":
          data = [];
          obj = {};

          this.operations.forEach((element) => {
            obj = {
              "DATE OPERATION(S)": element.created_at,
              "LIBELLE(S)": element.libelle,
              "PERSONNEL(S)": element.compte_tiers,
              "CREANCIER(S)": element.solde ? element.solde : 0,
              "ENTRÉE(S)": element.entree ? element.entree : 0,
              "SORTIE(S)": element.sortie ? element.sortie : 0,
              "SOLDE(S)": element.solde ? element.solde : 0,
            };

            data.push(obj);
          });
          break;

        case "Retrait Associés":
          data = [];
          obj = {};

          this.operations.forEach((element) => {
            obj = {
              "DATE OPERATION(S)": element.created_at,
              "LIBELLE(S)": element.libelle,
              "ASSOCIATION / IMPORTATEUR(S)": element.compte_tiers,
              "RETIRÉ PAR(S)": element.retirer_par,
              "MONTANT(S)": element.entree ? element.entree : 0,
            };

            data.push(obj);
          });
          break;

        default:
          data = [];
          obj = {};

          this.operations.forEach((element) => {
            obj = {
              "DATE OPERATION(S)": element.created_at,
              "LIBELLE(S)": element.libelle,
              "ASSOCIATION / IMPORTATEUR(S)": element.compte_tiers,
              "ENTRÉE(S)": element.entree ? element.entree : 0,
              "SORTIE(S)": element.sortie ? element.sortie : 0,
              "SOLDE(S)": element.solde ? element.solde : 0,
            };

            data.push(obj);
          });
          break;
      }

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des opérations",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.block-text .card-body {
  padding-bottom: 0px;
}

#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

@media screen and (max-width: 1000px) {
  .search {
    margin-right: 50% !important;
  }
  .block-text-media {
    margin-right: 5% !important;
  }
}
</style>
